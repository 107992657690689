import React from 'react';
import classNames from 'classnames';
import './../styles/image.scss';

const Image: React.FC<any> = ({ data, classes }) => {
    if (!data?.url || Object.keys(data.url).length === 0) return <></>;

    return <img className={classNames('dynamic-asset-v2-image', classes?.image)} src={data.url} />;
};

export default Image;
