import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { CEiframeEventPayload, EventEmitterTypes } from 'types/event-emitter.type';
import { EventEmitterHelpers } from 'helpers/event-emitter.helpers';
import ComponentStore from 'components/data/ComponentStore';
import EditorData from 'components/editor-data/EditorData';
import { IframeSettings, IframeData } from '../../../creative-types/template-creative.class';

const BOTTOM_SPACING_PLAYBAR = 26;

interface Props {
    iframeSettings: IframeSettings<IframeData>;
    classes: any;
    scale?: number;
    takeScreenshot?: boolean;
}

const Iframe: React.FC<Props> = ({ iframeSettings, scale: initialScale, classes, takeScreenshot }) => {
    const [uuid, setUuid] = useState<string>('');
    const iframeRef = useRef<HTMLIFrameElement>(null);

    const hasValidUrl = iframeSettings.url && Object.keys(iframeSettings.url).length > 0;

    const { data: iframeData, width = 0, height = 0, frameWidth = width, frameHeight = height, style = {} } = iframeSettings;

    const bottomSpacing = (() => {
        if (iframeSettings.bottomSpacing) return iframeSettings.bottomSpacing;
        if (iframeData?.showPlaybar) return BOTTOM_SPACING_PLAYBAR;
        return 0;
    })();

    // If a scale is given as a prop, use that one. Otherwise, calculate the scale based on the width and height of the iframe
    const scale = (() => {
        if (initialScale) return initialScale;

        const scaleByWidth = width / (frameWidth || width);
        const scaleByHeight = height / (frameHeight || height);

        return Math.min(scaleByWidth, scaleByHeight);
    })();

    const scaledBottomSpacing = bottomSpacing / scale;

    useEffect(() => {
        const uuid = uuidv4();
        setUuid(uuid);

        // We need to create one function here, otherwise the removeEventListener will not work
        const handleMessage = (e: MessageEvent) => {
            handleFrameMessage(e, uuid);
        };

        // Start listening for iFrame events
        window.addEventListener('message', handleMessage);

        // Listen to event emitter from the creative editor v2
        if (iframeData?.version === 2) {
            EventEmitterHelpers.receive(EventEmitterTypes.CEiframe, handleIframeAction);
        }

        // Remove listeners
        return () => {
            window.removeEventListener('message', handleMessage);

            if (iframeData?.version === 2) {
                EventEmitterHelpers.remove(EventEmitterTypes.CEiframe, handleIframeAction);
            }
        };
    }, []);

    useEffect(() => {
        if (iframeRef.current) {
            sendFrameMessages(iframeData);
        }
    }, [iframeData]);

    const handleIframeLoad = () => {
        if (iframeRef.current) {
            sendFrameMessages(iframeData);
        }
    };

    // We can send certain actions to the iframe, e.g. play, pause, seek, etc.
    const handleIframeAction = (message: CEiframeEventPayload) => {
        if (message?.action) {
            sendFrameMessages({ action: message.action });
        }
    };

    /**
     *  Handle messages coming from the iframe, e.g. position updates
     **/
    const handleFrameMessage = (e, uuid?: string) => {
        // Data is not from frame
        if (e.origin === window.location.origin) {
            return false;
        }

        // No data provided or no callback provided
        if (!e.data) {
            return false;
        }

        const data = e.data;

        if (data.uuid === uuid) {
            if (e.data.type === 'message') {
                onAssetUpdate(data.model, data.value);
            }
        }
    };

    /**
     * Save to store
     * TODO this is only for CreativeEditor, maybe as a prop?
     **/
    const onAssetUpdate = (model: string, value: unknown) => {
        const localScopeId = ComponentStore.getItem('CreativeEditorV2', 'localScopeId');

        if (iframeSettings.assetUpdateModel && localScopeId) {
            EditorData.setModel(`${iframeSettings.assetUpdateModel}.${model}`, value, [], `scope-${localScopeId}`);
        }
    };

    /**
     * Send messages to frame
     */
    const sendFrameMessages = (messageData: any) => {
        if (!messageData) return;

        let url = iframeSettings.url || '';

        if (window.location.hostname === 'localhost') {
            /**
             * We're doing this on a specific host, because the template designer host, 'templates.bycape.io' has a 301 redirect to https.
             * So for that host the url should remain unchanged.
             */
            url = url.replace('https://templates.campaigndesigner.io', 'http://templates.campaigndesigner.io');
        }
        // When there is no scale in data, add it. Scale is being used to drag layers in Template Designer templates.
        if (!messageData.scale && !messageData.action) messageData.scale = width / frameWidth;

        // If we use the new creative overview, add the scale to the message data and adapt for the canvasZoom
        // TODO use prop?
        if (messageData.version === 2) {
            const canvasZoom: number | undefined = ComponentStore.getItem('CreativeOverview', 'canvasZoom');
            if (canvasZoom && typeof canvasZoom === 'number') {
                messageData.scale = scale * canvasZoom;
            } else {
                messageData.scale = scale;
            }
        }

        // TODO Add loaded like iframe.js?

        // Add the takeScreenshot property to the messageData.
        messageData.takeScreenshot = takeScreenshot;

        if (iframeRef.current) {
            iframeRef.current.contentWindow?.postMessage({ origin: window.location.origin, uuid, data: messageData }, url);
        }
    };

    const getParentStyle = () => {
        const parentStyle: React.CSSProperties = {
            width: frameWidth * scale,
            height: frameHeight * scale - 1 + bottomSpacing,
            position: 'relative',
            margin: 0,
            fontSize: 0,
            lineHeight: 0,
            ...style
        };

        return parentStyle;
    };

    const getIframeStyle = () => {
        const updatedFrameHeight = frameHeight + scaledBottomSpacing;

        let left = 0;
        let top = 0;

        if (scale < 1) {
            left = (-1 * (frameWidth - frameWidth * scale)) / 2;
            top = (-1 * (updatedFrameHeight - updatedFrameHeight * scale)) / 2;
        } else {
            left = Math.abs((frameWidth - frameWidth * scale) / 2);
            top = Math.abs((updatedFrameHeight - updatedFrameHeight * scale) / 2);
        }

        const iframeStyle: React.CSSProperties = {
            position: 'relative',
            transform: `scale(${scale})`,
            left: `${left}px`,
            top: `${top}px`,
            overflow: 'hidden',
            border: 'none',
            ...iframeSettings?.style
        };

        return iframeStyle;
    };

    if (!hasValidUrl) {
        return null;
    }

    // This makes it possible to get the visible formats in the editor (some are out of view)
    // It's used in the function where we get the optimal format to make a screenshot (getOptimalScreenshotFormat)
    const visibleFormat = iframeData?.format || '';

    return (
        <div style={getParentStyle()} data-visible-format={visibleFormat}>
            <iframe
                className={classNames(classes)}
                style={getIframeStyle()}
                width={frameWidth}
                height={frameHeight + scaledBottomSpacing}
                src={(iframeSettings.url || '').replace('http:', '').replace('https:', '')}
                ref={iframeRef}
                onLoad={handleIframeLoad}
                sandbox="allow-same-origin allow-scripts"
            />
        </div>
    );
};

export default Iframe;
